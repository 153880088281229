$mobile: 480px;
$tablet: 768px;
$small: 991px;
$large: 1200px;

$primary1: #f6f1ed;
$primary2: #fff2e8;
$primary3: #e6dfd9;
$primary4: #c9bcbc;

$secondary1: #a3134a;
$secondary2: #b50146;
$secondary3: #960145;
$secondary4: rgb(255, 244, 244);
$third1: #201e1c;
$third2: #36332f;
$third3: #4e4944;

$modal: #fafafa;

$buttonRipple: rgba(233, 163, 163, 0.7);

$bgColor: linear-gradient(to top right, rgba(246, 241, 237, 0.95) 10%, rgba(255, 242, 232, 0.95));
$bgColorLight: linear-gradient(to top right, rgba(246, 241, 237, 0.85) 10%, rgba(255, 242, 232, 0.85));
