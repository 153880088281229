/* Slideshow */

$player-width: 70vw;
.slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: $player-width;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    width: $player-width;
    border-radius: 40px;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #6a0dad;
}

.slideshow-container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    // width: 300px;
    height: 78vh !important;
    display: flex;
    justify-content: center;
}

.swiper-slide img {
    display: block;
    height: 100%;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: -1;
}

.gif-player-image {
    max-height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 991px) {
    .gif-player-image {
        max-width: 100%;
        object-fit: contain;
    }
}
.player-item {
    max-width: 80vw;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 5px !important;
}
