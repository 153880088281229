@import '../../../Styles/variables';

div > .button-container {
    margin-top: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    &:not(:last-child) {
        margin-right: 10px;
    }

    .floating-button {
        box-shadow: 2.5px 2.5px 1.25px $primary4;
        color: $primary1;
        font-size: 1.2rem;
    }

    button {
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        transition: background 400ms;
        color: $primary1;
        background-color: $third1;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        outline: 0;
        border: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
        cursor: pointer;

        &:enabled:active {
            background-color: $third2 !important;
            box-shadow: none !important;
            outline: 0;
        }

        &:disabled {
            opacity: 0.65;
        }
    }

    span.ripple {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: ripple 600ms linear;
        background-color: $buttonRipple;

        pointer-events: none;
    }

    @keyframes ripple {
        to {
            transform: scale(4);
            opacity: 0;
        }
    }

    @media only screen and (max-width: $small) {
        .floating-button {
            width: 100%;
        }
    }
}
